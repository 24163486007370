<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="presentation"
      class="wrap"
      :width="styleVariables"
      :height="styleVariables"
      :class="'color-' + color"
    >
      <circle
        class="base"
        cx="12"
        cy="12"
        r="11"
        fill="none"
        stroke-width="1"
        stroke="#f2f2f2"
      />
      <circle
        class="path"
        cx="12"
        cy="12"
        r="11"
        fill="none"
        stroke-width="1"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    /** ローディングアイコンの大きさ */
    size: {
      type: [Number, String],
      default: 'default'
    },
    /** ローディングアイコンの色 */
    color: {
      type: String,
      default: 'default',
      require: false
    }
  },
  computed: {
    styleVariables() {
      let iconSize = this.size
      if (isNaN(iconSize) === false) {
        iconSize = (iconSize / 1920) * 100
      } else {
        switch (iconSize) {
          case 'default':
            iconSize = (28 / 1920) * 100
            break
          case 'large':
            iconSize = (32 / 1920) * 100
            break
          case 'small':
            iconSize = (20 / 1920) * 100
            break
          case 'title':
            iconSize = (48 / 1920) * 100
            break
          case 'min':
            iconSize = (16 / 1920) * 100
            break
          case 'huge':
            iconSize = (64 / 1920) * 100
            break
          default:
            iconSize = (28 / 1920) * 100
        }
      }
      return iconSize + 'vw'
    }
  }
}
</script>

<style lang="scss" scoped>
.color {
  &-default {
    stroke: $text-main;
  }
  &-caution {
    stroke: $text-caution;
  }
  &-emphasis {
    stroke: $text-decoration;
  }
  &-gray {
    stroke: $text-sub;
  }
  &-link {
    stroke: $text-link;
  }
}

.wrap {
  transform-origin: center center;
  animation: rotate 2s linear infinite;
}

.path {
  position: relative;
  z-index: 2;
  animation: dash 1.5s ease-in-out infinite;
  stroke-dasharray: 72, 96;
  stroke-dashoffset: -8;
  stroke-linecap: round;
}

.base {
  position: relative;
  z-index: 1;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 96;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 56, 96;
    stroke-dashoffset: -40;
  }
  100% {
    stroke-dasharray: 56, 96;
    stroke-dashoffset: -88;
  }
}
</style>
