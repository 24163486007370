<template>
  <transition name="balloon-toggle" mode="out-in">
    <div
      v-if="showPopup"
      class="balloon"
      :class="{ 'balloon-error': error, 'balloon-bottom': isBottom }"
    >
      <div class="balloon-inner" :class="{ 'balloon-inner-error': error }">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    /** ポップアップを表示するかどうか */
    showPopup: {
      type: Boolean,
      default: false
    },
    /** エラーが起きているかどうか */
    error: {
      type: Boolean,
      default: false
    },
    /** 下向き矢印で表示 */
    isBottom: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.balloon {
  position: absolute;
  top: calc(100% + #{$space-sub});
  right: #{-$space-medium};
  border-radius: adjustVW(8);
  cursor: auto;
  box-shadow: $box-shadow-hover;
  z-index: 100;
  &-inner {
    position: relative;
    min-width: adjustVW(160);
    padding: $space-sub;
    background: $background;
    border-radius: adjustVW(8);
    z-index: 1;
    &-error {
      background: #fdf4f4;
    }
  }
  &-icon {
    position: absolute;
    top: $space-small;
    right: $space-small;
    opacity: 0;
    transition: opacity $transition-base ease-in;
    &:hover {
      opacity: 1;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: adjustVW(32);
      height: adjustVW(32);
      background: $background;
      border-radius: 9in;
      box-shadow: $box-shadow-main;
      z-index: 0;
      transform: translate(-50%, -50%);
    }
  }
  &-close {
    position: relative;
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    top: adjustVW(-10);
    right: $space-medium;
    width: adjustVW(24);
    height: adjustVW(24);
    background: $background;
    border-radius: adjustVW(4);
    box-shadow: $box-shadow-hover;
    z-index: -1;
    transform: rotate(45deg);
  }
  &-error {
    box-shadow: $border-radius-caution, $box-shadow-hover;
    &::after {
      background: #fdf4f4;
      box-shadow: $border-radius-caution, $box-shadow-hover;
    }
  }
  &-bottom {
    top: inherit;
    bottom: calc(100% + #{$space-sub});
    &::after {
      top: inherit;
      bottom: adjustVW(-10);
    }
  }
}
.balloon-toggle-enter-active,
.balloon-toggle-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.balloon-toggle-enter,
.balloon-toggle-leave-to {
  opacity: 0;
  transform: translateX(-$space-small);
  will-change: opacity, transform;
}
</style>
