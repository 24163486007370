<template>
  <div class="c-card">
    <div v-if="title" class="c-card-title">
      <texts :text="title" size="large" />
    </div>
    <slot />
  </div>
</template>

<script>
import texts from './text'

export default {
  components: {
    texts
  },
  props: {
    /** カードにタイトルをつける場合は渡す */
    title: {
      type: String,
      require: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-card {
  position: relative;
  padding: $space-small;
  background: $background;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
  transition: $transition-base;
  &-title {
    margin: 0 0 $space-sub;
  }
}
</style>
