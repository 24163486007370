<template>
  <popup-balloon-input
    class="input-popup-name-validation"
    :showPopup="showPopup"
    :error="error"
  >
    <texts
      class="input-popup-name-validation-title"
      :text="$t('common.duplicateValidationMessage')"
      size="min"
      :color="error ? 'caution' : 'default'"
      isShowAll
    />
    <div class="input-popup-name-validation-samples">
      <button
        v-for="(suggest, index) in nameValidationSuggests"
        :key="index"
        class="input-popup-name-validation-samples-button"
        tabindex="-1"
        @click.stop="selectSuggest(suggest)"
      >
        <text-with-icon :text="suggest" size="small" iconName="edit" />
      </button>
    </div>
  </popup-balloon-input>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import TextWithIcon from '@/components/molecules/text-with-icon.vue'
import popupBalloonInput from '@/components/atoms/popup-balloon-input.vue'

export default {
  components: {
    texts,
    TextWithIcon,
    popupBalloonInput
  },
  props: {
    /** エラー時に赤色で表示 */
    error: {
      type: Boolean,
      default: false,
      require: false
    },
    /** ポップアップを表示 */
    showPopup: {
      type: Boolean,
      default: false,
      require: false
    },
    /** 表示するサジェストの配列 */
    nameValidationSuggests: Array
  },
  methods: {
    selectSuggest(suggest) {
      this.$emit('select-suggest', suggest)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-popup {
  &-name-validation {
    top: calc(100% + #{$space-base});
    right: adjustVW(2);
    max-width: 95%;
    border-radius: adjustVW(4);
    &::v-deep.balloon {
      &::after {
        top: adjustVW(-8);
        right: adjustVW(14);
        width: adjustVW(16);
        height: adjustVW(16);
        border-radius: adjustVW(2);
      }
    }
    &-title {
      margin-bottom: $space-small;
      white-space: pre-line;
    }
    &-samples {
      display: flex;
      flex-direction: column;
      padding: 0 $space-base;
      margin-bottom: $space-base;
      background: $background;
      border-radius: adjustVW(8);
      box-shadow: $box-shadow-main;
      &-button {
        max-width: adjustVW(420);
        padding: $space-small $space-base;
        border-bottom: $border-sub;
        text-align: left;
        transition: $transition-base;
        &:hover {
          opacity: 0.4;
        }
        &:last-of-type {
          border: none;
        }
      }
    }
  }
}
</style>
