<template>
  <transition
    name="toggle-contents"
    mode="out-in"
    @after-enter="$emit('after-enter')"
  >
    <slot />
  </transition>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.toggle-contents-enter-active,
.toggle-contents-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-contents-enter,
.toggle-contents-leave-to {
  opacity: 0;
  transform: translateY(
    -$space-small
  ); // スクロールバーが下方向へのトランジッションだと見えてしまうため、上方向に変更
  will-change: opacity, transform;
}
</style>
