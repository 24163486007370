<template>
  <div class="c-textblock-icon-wrap" :class="[pos, size]">
    <div
      v-if="iconName"
      v-tooltip.auto="tooltip"
      class="c-textblock-icon-inner"
    >
      <icon
        :iconName="iconName"
        :size="size"
        :color="color"
        :isButton="isButton"
        @icon-click="$emit('icon-click')"
      />
    </div>
    <texts
      :text="text"
      :size="size"
      :color="color"
      :isShowAll="isShowAll"
      :isBold="isBold"
    >
      <slot />
    </texts>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import icon from '@/components/atoms/icon.vue'

export default {
  name: 'textWithIcon',
  components: {
    texts,
    icon
  },
  props: {
    /** 表示するテキスト */
    text: [String, Number],
    /** 文字とアイコンの大きさ ['default', 'title', 'sub-title', 'big', 'large', 'small', 'min'] */
    size: {
      type: [String, Number],
      require: false,
      default: 'default'
    },
    /** 表示するアイコン */
    iconName: {
      type: String,
      require: false
    },
    /** 左右どちらにアイコンを表示するか ['left', 'right'] */
    pos: {
      type: String,
      require: false,
      default: 'left',
      validator: function (value) {
        return ['left', 'right'].indexOf(value) !== -1
      }
    },
    /** 文字の色やデコレーション ['default', 'gray', 'emphasis', 'link', 'link-default', 'link-caution', 'function', 'caution', 'accept', 'disabled', 'off', 'text-button'] */
    color: {
      type: String,
      require: false,
      default: 'default'
    },
    /** アイコンをボタンにするかどうか */
    isButton: {
      type: Boolean,
      require: false,
      default: false
    },
    /** 折り返して全文表示するかどうか */
    isShowAll: {
      type: Boolean,
      require: false,
      default: false
    },
    /** 太文字かどうか */
    isBold: {
      type: Boolean,
      require: false,
      default: false
    },
    /** tooltipを表示する場合に渡す。v-tooltip */
    tooltip: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.c-textblock-icon {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    &.right {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
  &-inner {
    padding: 0 $space-sub 0 0;
    .small & {
      padding: 0 $space-sub 0 0;
    }
    .min & {
      padding: 0 $space-base 0 0;
    }
    .right & {
      padding: 0 0 0 $space-small;
    }
    .right.small & {
      padding: 0 0 0 $space-sub;
    }
  }
}
</style>
