export default {
  data() {
    return {
      mountedTimer: 0,
      firstUpdated: false
    }
  },
  beforeMount() {
    this.mountedTimer = new Date().getTime()
  },
  updated() {
    if (this.firstUpdated) return
    this.firstUpdated = true
    const settedMountedTimer = new Date().getTime()
    const resMountedTimer = settedMountedTimer - this.mountedTimer
    this.$gtmDataLayer.sendEventCategory(
      'page-set-timer',
      this.$route.name,
      resMountedTimer
    )
  },
  beforeDestroy() {
    this.mountedTimer = 0
    this.firstUpdated = false
  }
}
